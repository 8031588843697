<template>
    <div class="Cevaluation" >
        <div class="nav">
            <van-row>
                <van-col span="5">
                    <div @click="back()">
                        <van-icon name="arrow-left" />
                    </div>
                </van-col>
                <van-col span="14">
                    {{lang.personal_evaluation}}
                </van-col>
                <van-col span="4">
                    <div @click="toggleLanguage">
                        <img class="lang-img" :src="lang.langBtn">
                    </div>
                </van-col>
            </van-row>
        </div>

        <div class="content">

            <van-row>
                <van-col offset="1" span="22">
                    <div class="name">{{name}}:</div>
                </van-col>
                <van-col offset="1" span="22">
                    <textarea class="msg" rows="10" v-model="feedback.content" :placeholder="lang.fill_in_class_rating"></textarea>
                </van-col>
            
                <van-col offset="1" span="22" class="left">
                    <van-uploader 
                        class="upload"
                        accept='image/*'
                        :before-read="beforeReadImg"
                        :after-read="afterReadImg"
                        v-model="imageList"
                        max-count="9" 
                        multiple
                        :max-size="10 * 1024 * 1024"
                        @oversize="onOversizeImg"
                        image-fit="contain"
                        :before-delete="deleteImg"
                         />
                </van-col>

                <van-col offset="1" span="22">
                    <div class="uploadJob file" v-for="file in feedback.uploadedList" :key="file">
                        <van-row>
                            <van-col offset="1" span="20">
                                <div class="fileName">{{file.name}}</div>
                            </van-col>
                            <van-col span="2">
                            <van-icon  size="20px" name="cross" @click="deleteUploadedFile(file)"/>
                            </van-col>
                        </van-row>
                    </div>
                    <div class="uploadJob" v-show="feedback.uploadedList.length<5">
                        <van-uploader 
                            max-count="1" 
                            accept="application"
                            compressed="false"
                            v-model="uploadFile"
                            :max-size="10 * 1024 * 1024"
                            @oversize="oversizeUploadedFile"
                            :before-read="beforeReadUploadedFile"
                            :after-read="afterReadUploadedFile"
                            >
                            {{lang.uploadpdforword}}
                        </van-uploader>
                    </div>
                </van-col>

            </van-row>
        </div>

        <div class="foot">
            <van-row>
                <van-col offset="1" span="21">
                    <div class="form-button" @click="submit">{{lang.submit}}</div>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs,ref,onMounted  } from 'vue';
import Common from "../../library/Common";
import { useRouter } from 'vue-router';
import request from '../../library/MyRequest';
import { Dialog, Toast } from 'vant';
export default {
    name: 'PersonalEvaluation',
    setup(){
        const mediaType ={
            IMAGE:1, //Image
            VIDEO:2, //Video
        }
        const common = Common;
        common.chechLogin(router);
        const router = useRouter();
        const lesson_id = router.currentRoute.value.params.lesson_id;
        const student_id = router.currentRoute.value.params.student_id;

        const imageList = ref([]);
        const uploadFile = ref([]);
        let state = reactive({
            lang:{
                langBtn:"",
                personal_evaluation:"",
                pictureSize:"",
                justUpload:"",
                uploading:"",
                fill_in_class_rating:"",
                justUploadjob:"",
                DeleteThisJob:"",
                JobUploaded:"",
                publish:"",
                select_file:"",
                submitSuccess:"",
                filenametoolong:"",
                uploadpdforword:"",
                submit:"",
            },
            feedback:{
                content:"",
                uploadedList:[],
            },
            name:router.currentRoute.value.params.student_name,
        });
        

        common.initLanguage(state.lang);
        state.lang.langBtn = common.getLanguageBtn();
        const toggleLanguage = function(){
            common.toggleLanguage();
            common.initLanguage(state.lang);
            state.lang.langBtn = common.getLanguageBtn();
        }
        
		const back = function(){
           router.push({name: 'rollCall',params:{id:lesson_id,student_id:student_id}});
        }

        const onOversizeImg = () => {
            Toast(state.lang.pictureSize);
        }
        const beforeReadImg = (File) => {
            // const chenkFile = function(file){
            //     return file.type == "image/png" || file.type == "image/jpeg";
            // }
            console.log(File);
            if(File.length>0){  //多文件
                console.log("上传多个文件");
                // for(let item of File){
                //     if(!chenkFile(item)){
                //         Toast(state.lang.justUpload);
                //         return false;
                //     }
                // }
            }else{  //单文件
                console.log("上传单文件");
                // if(!chenkFile(File)){
                //    Toast(state.lang.justUpload);
                //     return false;
                // }
            }
            return true;
        }
        const afterReadImg = async(event) =>{
            console.log(event);
            const uploadImage = async(file)=>{
                var uploadFile = {
                    url:null,
                    name:null,
                    media_type:mediaType.IMAGE,
                    error:null,
                }
                const formData = new FormData();
                formData.append('file',file);
                try{
                    var res = await request.upload(formData);
                    if(res.status == 200){
                        uploadFile.url = res.data.url;
                        uploadFile.name = file.name;
                        return uploadFile;
                    }else{
                        uploadFile.error = `upload images https res.status:${res.status}`;
                    }
                    return 
                }catch(e){
                    uploadFile.error = e.message;
                    console.log(e.message);
                }
                return uploadFile;
            }
           Toast.loading({
                message:state.lang.uploading,
                forbidClick: true,
                overlay:true,
            });
            if(event.length>0){
                for(let item of event){
                    imageList.value.pop();
                }
                for(let item of event){
                    let uploadFile = await uploadImage(item.file);
                    if(uploadFile.error == null){
                        imageList.value.push(uploadFile);
                    }else{
                        Toast.clear();
                        Toast(uploadFile.error);
                    }
                }
            }else{
                imageList.value.pop();
                let uploadFile = await uploadImage(event.file);
                if(uploadFile.error == null){
                    imageList.value.push(uploadFile);
                }else{
                    Toast.clear();
                    Toast(uploadFile.error);
                }
            }
            Toast.clear();
        }
        const deleteImg = function(proxy){
            return true;
        }

        const oversizeUploadedFile = function(){
            Toast(state.lang.justUploadjob);
        }
        const hasFile = function(filename){
            for(var file of state.feedback.uploadedList){
                if(file.name == filename){
                    return true;
                }
            }
            return false;
        }
        const beforeReadUploadedFile = (File) => {
            if(File.name.length>100){
                Toast(state.lang.filenametoolong);
                return false;
            }else if(hasFile(File.name)){
                Toast(state.lang.JobUploaded);
                return false; 
            }
            if(File.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || 
               File.type == "application/msword" || 
               File.type == "application/pdf"
               ){
                return true;
            }else{
                Toast(state.lang.justUploadjob);
                return false;
            }
        }
        const afterReadUploadedFile = async(event)=>{
            console.log("afterReadUploadedFile");
            uploadFile.value.splice(0,1);
            Toast.loading({
                message:state.lang.uploading,
                forbidClick: true,
                overlay:true,
            });
            const file = event.file;
            const formData = new FormData();
            formData.append('file',file);
            try{
                var uploadRes = await request.upload(formData);
                if(uploadRes.status == 200){
                    var uploadfile = {
                        name:file.name,
                        url:uploadRes.data.url
                    };
                    state.feedback.uploadedList.push(uploadfile);
                }
            }catch(e){
                Toast(e.message);
                console.log(e.message);
            }
            
            Toast.clear();
        }
        const deleteUploadedFile = function(item){
            Dialog.confirm({
                message:state.lang.DeleteThisJob,
                confirmButtonText:state.lang.yes,
                cancelButtonText:state.lang.no,
            })
            .then(async() => {
                state.feedback.uploadedList.splice(state.feedback.uploadedList.indexOf(item),1);
            });
        }


        const submit = async()=>{
            Toast.loading({
                message:state.lang.uploading,
                forbidClick: true,
                overlay:true,
            });

            var params = {
                lesson_id:lesson_id,
                clear_all_files:true,
                admission_no:student_id,
                info:state.feedback.content,
                images:imageList.value.map((item)=>{return {name:"",url:item.url};}),
                files:state.feedback.uploadedList.map((item)=>{return item;}),
            }
            try{
                var res = await request.post(`lesson/feedback-personal/`,params);
                Toast.clear();
                if(res.status == 201){
                    Toast({
                        forbidClick:true,
                        message:state.lang.submitSuccess,
                        onClose:()=>{
                            back();
                        }
                    });
                }
            }catch(e){
                Toast.clear();
                Toast(e.message);
                console.log(e.message);
            }
        }

        const getFeedback = async()=>{
            Toast.loading({
                message:state.lang.uploading,
                forbidClick: true,
                overlay:true,
            });
            try{
                var res = await request.get(`lesson/feedback-personal/?lesson_id=${lesson_id}&admission_no=${student_id}&force=1`);
                if(res.status == 200){
                    let data = res.data;
                    state.feedback.content = data.info;
                    state.feedback.uploadedList = data.files;
                    imageList.value = data.images.map((item)=>{return {url:item} ;});
                }
            }catch(e){
                console.log(e.message);
            }
            Toast.clear();
        }
        

        onMounted(async()=>{
            if(!lesson_id || !student_id){
                back();
            }else{
                await getFeedback();
            }
        });
        return {
            ...toRefs(state),
            common,
            imageList,
            uploadFile,

            toggleLanguage,
            back,
            onOversizeImg,
            beforeReadImg,
            afterReadImg,
            deleteImg,

            oversizeUploadedFile,
            beforeReadUploadedFile,
            afterReadUploadedFile,
            deleteUploadedFile,

            submit
        }
    }
}
</script>

<style>
.content{
    height:calc(100% - 250px);
    overflow-y:auto;
    overflow-x:hidden;
}
.name{
  font-size: 32px;
  width: 100%;
  text-align: left; 
  margin-bottom: 20px;
  font-weight: 600;
}
.Cevaluation{
   width: 100%;
   height:100%; 
   background:#fff;
}

.msg{
    font-size: 28px;
    border:0px;
    width: 100%;
}


.uploadJob{
    width: 100%;
    height: 100px;
    line-height: 100px;
    border:5px solid #4284FD;
    border-radius: 20px;
    color: #4284FD;
    font-size: 32px;
    font-weight: 600;
    margin-top:20px;

}

.file{
    border:5px solid green;
    color: green;
}

.fileName{
    width: 100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}

.foot{
    position:fixed;
    width: 100%;
    bottom:0px;
    background-color: #fff;
    font-size: 28px;
    padding:30px 0;
}
</style>